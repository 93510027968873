import axios from '@axios'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import { ref, watch, computed } from '@vue/composition-api'
import pushStateFiltersParams from '@/helpers/pushStateFiltersParams'
import store from '@/store'

export default function useReportsView(root) {
  // Use toast
  const toast = useToast()

  const refReportsViewList = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'name', label: 'Name', sortable: true },
    { key: 'created_by', label: 'created by', sortable: true },
    { key: 'created_at', label: 'created at', sortable: true },
    { key: 'updated_at', label: 'Last updated at', sortable: true },
    { key: 'actions' },
  ]
  const defaultPerPage = Number(localStorage.getItem('reportsViewPerPage'))
  const perPage = ref(defaultPerPage || 10)
  const totalItems = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('created_at')
  const isSortDirDesc = ref(true)
  let debounceTimeout = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refReportsViewList.value ? refReportsViewList.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalItems.value,
    }
  })

  const refetchData = () => {
    refReportsViewList.value.refresh()
  }
  watch([currentPage, perPage, searchQuery],
    ([currentPageVal, perPageVal, searchQueryVal],
      [currentPagePrevVal, perPagePrevVal, searchQueryPrevVal]) => {
      if (currentPageVal !== currentPagePrevVal) {
        pushStateFiltersParams(root, 'admin-reports-view-list', 'currentPage', currentPageVal)
      }
      if (perPageVal !== perPagePrevVal) {
        pushStateFiltersParams(root, 'admin-reports-view-list', 'perPage', perPageVal)
      }
      if (searchQueryVal !== searchQueryPrevVal) {
        clearTimeout(debounceTimeout)
        debounceTimeout = setTimeout(() => {
          pushStateFiltersParams(root, 'admin-reports-view-list', 'searchQuery', searchQueryVal)
        }, 250)
      }
    })

  const fetchReportViews = (ctx, callback) => {
    const payloadData = {
      perPage: perPage.value,
      page: currentPage.value,
      sortBy: sortBy.value,
      sortDesc: isSortDirDesc.value,
    }
    if (searchQuery.value) payloadData.name = searchQuery.value

    store
      .dispatch('app-reports/fetchReportViews', payloadData)
      .then(response => {
        const { data } = response.data
        const { total } = response.data.meta

        callback(data)
        totalItems.value = total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching Reports Views' list",
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const fetchReportViewsList = () => axios
    .get('/auth/report-views/')
    .then(response => response.data.data)
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error fetching Report View item',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))
  const fetchReportView = ID => axios
    .get(`/auth/report-views/${ID}`)
    .then(response => response.data.data)
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error fetching Report View item',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  const postReportView = queryParams => axios
    .post('/auth/report-views', queryParams)
    .then(response => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'New report view item has created',
          icon: 'ThumbsUpIcon',
          variant: 'info',
        },
      })
      return response.data.data
    })
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error creating report view item',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  const updateReportView = queryParams => axios
    .put(`/auth/report-views/${queryParams.id}`, queryParams)
    .then(response => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Report view was successfully updated',
          icon: 'ThumbsUpIcon',
          variant: 'info',
        },
      })
      return response.data.data.id
    })
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error updating report view',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  const deleteReportView = ID => axios
    .delete(`auth/report-views/${ID}`)
    .then(() => {
      refetchData()
      toast({
        component: ToastificationContent,
        props: {
          title: 'Success deleting report view item',
          icon: 'Trash2Icon',
          variant: 'info',
        },
      })
    })
    .catch(() => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error deleting report view item',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    })

  const executeReportView = queryParams => axios
    .post('/auth/report-views/execute', queryParams)
    .then(response => response.data.result)
    .catch(error => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error executing report view',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
      return error.response
    })

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    tableColumns,
    perPage,
    currentPage,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    totalItems,
    isSortDirDesc,
    refReportsViewList,
    fetchReportViews,
    fetchReportViewsList,
    fetchReportView,
    postReportView,
    updateReportView,
    deleteReportView,
    executeReportView,

    refetchData,
  }
}
